<template>
    <div id="app">
        <MazPhoneNumberInput
            v-model="phoneNumber"
            :default-country-code=defaultCountryCode
            :translations="{
                countrySelectorLabel: countrySelectorLabel,
                countrySelectorError: countrySelectorError,
                phoneNumberLabel: phoneNumberLabel,
                example: example
            }"
        />
        <button
            class="select"
            :disabled="!phoneNumber"
            :style="{ background: theme }"
            @click="select"
        >
            OK
        </button>
    </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5'
import { MazPhoneNumberInput } from 'maz-ui'
import LocalizedStrings from 'localized-strings'

let strings = new LocalizedStrings(
    {
        en: {
            countrySelectorLabel: 'Country code',
            countrySelectorError: 'Choose country',
            phoneNumberLabel: 'Phone number',
            example: 'Example:'
        },
        fr: {
            countrySelectorLabel: 'Code pays',
            countrySelectorError: 'Choisir un pays',
            phoneNumberLabel: 'Numéro de téléphone',
            example: 'Exemple :'
        },
        de: {
            countrySelectorLabel: 'Landesvorwahl',
            countrySelectorError: 'Legen Sie ein Land fest',
            phoneNumberLabel: 'Telefonnummer',
            example: 'Beispiel:'
        }
    },
    {
        customLanguageInterface: () => {
            return new Intl.DateTimeFormat().resolvedOptions().locale
        }
    }
)

export default {
    components: { MazPhoneNumberInput },
    data () {
        return {
            theme: '#333333',
            momentsSDK: null,
            phoneNumber: null,
            countrySelectorLabel: strings.countrySelectorLabel,
            countrySelectorError: strings.countrySelectorError,
            phoneNumberLabel: strings.phoneNumberLabel,
            example: strings.example,
            defaultCountryCode: new Intl.DateTimeFormat().resolvedOptions().locale.slice(-2).toUpperCase()
        }
    },
    methods: {
        select () {
            if (!this.phoneNumber || !this.momentsSDK) {
                return
            }

            this.momentsSDK.sendMessage({ text: this.phoneNumber })
            this.momentsSDK.close()
        }
    },
    async created () {
        createMomentsSDK({
            title: 'Phone Number',
            icon: `${window.location.origin}${window.location.pathname}/favicon.png`
        }).then(momentsSDK => {
            this.momentsSDK = momentsSDK
        })
    }
}
</script>

<style lang="sass">
    *
        margin: 0
        padding: 0
        box-sizing: border-box
        outline: none

    html, body, #app
        height: 100%
        width: 100%

    #app
        padding: 10px

    button.select
        width: 100%
        margin-top: 15px
        padding: 10px
        color: #ffffff
        border: none
        border-radius: 4px
        font-size: 16px
        -webkit-backface-visibility: hidden
        transition: all .3s
        cursor: pointer
        &:hover
            opacity: 0.8
        &:active
            opacity: 0.5
        &:disabled
            opacity: 0.2

    @import './src/assets/styles/main.scss'
</style>
