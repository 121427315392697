import Vue from 'vue'
import App from './App.vue'
import "maz-ui/lib/css/index.css";
import { MazPhoneNumberInput } from 'maz-ui'

Vue.use(MazPhoneNumberInput);

Vue.config.productionTip = false

new Vue({
    render: h => h(App)
}).$mount('#app')
